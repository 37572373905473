import { useEffect } from 'react';
import { Path, UseFormSetValue, UseFormWatch } from 'react-hook-form';

type ReactHookFormParams<T> = {
  watch: UseFormWatch<T>;
  setValue: UseFormSetValue<T>;
  isDirty: boolean;
};

export function getPersistedFormValue<T, K extends keyof T>(
  formName: string,
  fieldName: K
): T[K] | null {
  const str = window.localStorage.getItem(formName);
  if (!str) {
    return null;
  }
  const values = JSON.parse(str) as T;
  return values[fieldName];
}

function useFormPersist<T>(
  name: string,
  { watch, setValue, restore, isDirty }: ReactHookFormParams<T> & { restore?: boolean }
): {
  clear: () => void;
} {
  const values = watch();

  useEffect(() => {
    const str = window.localStorage.getItem(name);
    if (str) {
      const values = JSON.parse(str);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const dataRestored: any = {};

      if (restore) {
        Object.keys(values).forEach((key) => {
          dataRestored[key] = values[key];
          setValue(key as Path<T>, values[key]);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (isDirty) {
      window.localStorage.setItem(name, JSON.stringify(values));
    }
  });

  return {
    clear: () => window.localStorage.removeItem(name),
  };
}

export default useFormPersist;
