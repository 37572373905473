import { useUser } from '@auth0/nextjs-auth0';
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  Box,
  Stack,
  Heading,
} from '@chakra-ui/react';
import dynamic from 'next/dynamic';
import { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import ReactSelect from 'react-select';
import countryList from 'react-select-country-list';

import frontendTheme, { chakraFrontendThemeReactSelectStyles } from '../../styles/frontendTheme';
import { ageOptions, SubscribeFormValues } from '../../types/FormValues';
import { FormPaneWrapper } from '../form/FormPaneWrapper';

// See https://github.com/JedWatson/react-select/issues/3590
// for why this is currently required.
const AusPostcodeSuburbField = dynamic(() => import('../form/AusPostcodeSuburbField'), {
  ssr: false,
});

const CustomerDetailsFormPane = (props: {
  isCompanyOrOrg: boolean;
  isRecurring: boolean;
  shows: string[];
  title?: string;
  isPhoneNumberRequired?: boolean;
  cardCount?: number;
  hasPetName: boolean;
}): JSX.Element => {
  const { user } = useUser();

  const countryOptions = useMemo(() => countryList().getData(), []);

  const {
    control,
    watch,
    register,
    formState: { errors },
  } = useFormContext<SubscribeFormValues>();
  const selectedCountry = watch('country');

  const auPostcodeSuburbError = errors.auPostcodeSuburb;
  const selectStyles = useMemo(
    () => chakraFrontendThemeReactSelectStyles(auPostcodeSuburbError !== undefined),
    [auPostcodeSuburbError]
  );

  const cardCount = props.cardCount ?? 1;

  return (
    <FormPaneWrapper>
      {props.title && (
        <Heading as="h3" size="lg" mb="4" color={frontendTheme.primaryButtonBackground}>
          {props.title}
        </Heading>
      )}
      <Stack direction="column" spacing="4">
        {props.hasPetName && (
          <FormControl>
            <FormLabel>
              Pet Name
              <Input
                variant="filled"
                mt="2"
                {...register('petName', { required: false })}
                type="text"
                placeholder="e.g. Bingo the Dog, Charlie the Cat"
              />
            </FormLabel>
          </FormControl>
        )}
        <FormControl isInvalid={errors.firstName !== undefined}>
          <FormLabel>
            First name
            <Input
              variant="filled"
              mt="2"
              {...register('firstName', { required: true })}
              type="text"
            />
          </FormLabel>
          <FormErrorMessage>
            {errors.firstName && <span>This field is required</span>}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={errors.lastName !== undefined}>
          <FormLabel>
            Last name
            <Input
              variant="filled"
              mt="2"
              {...register('lastName', { required: true })}
              type="text"
            />
          </FormLabel>
          <FormErrorMessage>
            {errors.lastName && <span>This field is required</span>}
          </FormErrorMessage>
        </FormControl>

        {!props.isRecurring && (
          <FormControl isInvalid={errors.email !== undefined}>
            <FormLabel>
              Email
              <Input
                variant="filled"
                mt="2"
                {...register('email', {
                  required: true,
                  pattern: {
                    value: /\S+@\S+\.\S+/,
                    message: 'Entered value does not match email format',
                  },
                })}
                type="email"
              />
            </FormLabel>
            <FormErrorMessage>
              {errors.email && <span>{errors.email.message || 'This field is required'}</span>}
            </FormErrorMessage>
          </FormControl>
        )}

        {props.isRecurring && (
          <FormControl>
            <FormLabel>
              Email
              <Input variant="filled" mt="2" readOnly value={user?.email || ''} type="text" />
            </FormLabel>
          </FormControl>
        )}

        <FormControl isInvalid={errors.telephoneNumber !== undefined}>
          <FormLabel>
            Phone number
            <Input
              variant="filled"
              mt="2"
              {...register('telephoneNumber', { required: props.isPhoneNumberRequired || false })}
              type="text"
            />
          </FormLabel>
          {props.isPhoneNumberRequired && (
            <FormErrorMessage>
              {errors.telephoneNumber && <span>This field is required</span>}
            </FormErrorMessage>
          )}
          {!props.isPhoneNumberRequired && (
            <FormHelperText>
              This is optional but will allow us to contact you via phone if we have a query about
              your order
            </FormHelperText>
          )}
        </FormControl>

        {props.isCompanyOrOrg && (
          <FormControl>
            <FormLabel>
              Company or band name
              <Input variant="filled" mt="2" {...register('orgName')} type="text" />
            </FormLabel>
          </FormControl>
        )}

        {cardCount > 1 && (
          <FormControl isInvalid={errors.numberOfCards !== undefined}>
            <FormLabel>
              How many cards would you like? (max {cardCount})
              <Input
                variant="filled"
                mt="2"
                {...register('numberOfCards', {
                  required: true,
                  min: 1,
                  max: cardCount,
                  valueAsNumber: true,
                })}
                type="number"
              />
            </FormLabel>
            <FormErrorMessage>
              {errors.numberOfCards && (
                <span>{errors.numberOfCards.message || `Number between 1 and ${cardCount}`}</span>
              )}
            </FormErrorMessage>
          </FormControl>
        )}

        <FormControl>
          <FormLabel>
            Age
            <Box mt="2">
              <Controller
                name="age"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    styles={selectStyles}
                    {...field}
                    options={ageOptions}
                    instanceId="age"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    name={field.name}
                  />
                )}
              />
            </Box>
          </FormLabel>
          <FormHelperText>
            It would be great to know your age so we can better understand our supporter base :)
          </FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel>
            Country
            <Box mt="2">
              <Controller
                name="country"
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    styles={selectStyles}
                    {...field}
                    options={countryOptions}
                    instanceId="country"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    name={field.name}
                  />
                )}
              />
            </Box>
          </FormLabel>
        </FormControl>

        <FormControl isInvalid={errors.address1 !== undefined}>
          <FormLabel>
            Address 1
            <Input
              variant="filled"
              mt="2"
              {...register('address1', { required: true })}
              type="text"
            />
          </FormLabel>
          <FormErrorMessage>
            {errors.address1 && <span>This field is required</span>}
          </FormErrorMessage>
        </FormControl>

        <FormControl>
          <FormLabel>
            Indigenous Country / Address 2
            <Input variant="filled" mt="2" {...register('address2')} type="text" />
          </FormLabel>
        </FormControl>

        {selectedCountry?.value === 'AU' && (
          <FormControl isInvalid={errors.auPostcodeSuburb !== undefined}>
            <FormLabel>
              Suburb/Town
              <Box mt="2">
                <Controller
                  name="auPostcodeSuburb"
                  control={control}
                  rules={{
                    validate: (value) =>
                      selectedCountry &&
                      (selectedCountry.value !== 'AU' || (value !== null && value !== undefined)),
                  }}
                  render={({ field }) => (
                    <AusPostcodeSuburbField
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      value={field.value!}
                      name={field.name}
                      styles={selectStyles}
                    />
                  )}
                />
              </Box>
            </FormLabel>
            <FormErrorMessage>
              {errors.auPostcodeSuburb && <span>Field is required</span>}
            </FormErrorMessage>
            <FormHelperText>Start typing to search for postcode or suburb/town</FormHelperText>
          </FormControl>
        )}

        {selectedCountry?.value !== 'AU' && (
          <>
            <FormControl isInvalid={errors.suburb !== undefined}>
              <FormLabel>
                Suburb / Town
                <Input
                  variant="filled"
                  mt="2"
                  {...register('suburb', {
                    validate: (value) =>
                      selectedCountry !== null &&
                      (selectedCountry.value === 'AU' || (value !== null && value.length > 0)),
                  })}
                  type="text"
                />
              </FormLabel>
              <FormErrorMessage>
                {errors.suburb && <span>This field is required</span>}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <FormLabel>
                State
                <Input variant="filled" mt="2" {...register('state')} type="text" />
              </FormLabel>
            </FormControl>

            <FormControl>
              <FormLabel>
                Postcode
                <Input variant="filled" mt="2" {...register('postcode')} type="text" />
              </FormLabel>
            </FormControl>
          </>
        )}
      </Stack>
    </FormPaneWrapper>
  );
};

export default CustomerDetailsFormPane;
