import { Box } from '@chakra-ui/react';
import styled from '@emotion/styled';
import React from 'react';
import { useInView } from 'react-intersection-observer';

import frontendTheme from '../styles/frontendTheme';

export function useStickyMonitor<T>(): {
  aboveStickyDivRef: (node: T | undefined | null) => void;
  aboveStickyInView: boolean;
} {
  const { ref: aboveStickyDivRef, inView: aboveStickyInView } = useInView({
    initialInView: true,
    threshold: 0,
  });

  const resultRef = aboveStickyDivRef as (node: T | undefined | null) => void;

  return {
    aboveStickyDivRef: resultRef,
    aboveStickyInView,
  };
}

export function BottomSection(props: { children: React.ReactNode }): JSX.Element {
  return <Box bg={frontendTheme.lightYellowBackground}>{props.children}</Box>;
}

const StickyDiv = styled.div`
  position: sticky;
  top: 0;
  z-index: 500;
  margin-bottom: 4rem;
  padding-top: 1rem;
  background-color: ${frontendTheme.lightYellowBackground};

  &.sticky-stuck {
    background: white;
    transition: background 0.5s;
    box-shadow: 0 2px 2px -2px rgb(0 0 0 / 15%);
  }
`;

export function Sticky(props: {
  aboveStickyInView: boolean;
  children: React.ReactNode;
}): JSX.Element {
  return (
    <StickyDiv className={props.aboveStickyInView ? '' : 'sticky-stuck'}>
      {props.children}
    </StickyDiv>
  );
}
