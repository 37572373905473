import { Box } from '@chakra-ui/react';
import React from 'react';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
};

export const FormPaneWrapper = ({ children }: Props): JSX.Element => {
  return (
    <Box mb="12" p="6" bgColor="#fdefa9">
      {children}
    </Box>
  );
};
