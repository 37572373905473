import { OutstandingOderStatus } from '../lib/rails_api/retrieveSavedSubscriptionOrderForCheckout';
import { PaymentTerm } from './PaymentTerm';

export type AusPostcodeSuburbFieldOption = {
  label: string;
  value: {
    location: string;
    postcode: number;
    state: string;
  };
};

export const agePreferNotToSayOption = {
  value: 'not_specified',
  label: 'Prefer not to say',
};

export const ageOptions = [
  {
    value: 'under_18',
    label: 'Under 18 years old',
  },
  {
    value: '18-24',
    label: '18 – 24 years old',
  },
  {
    value: '25-34',
    label: '25 – 34 years old',
  },
  {
    value: '35-44',
    label: '35 – 44 years old',
  },
  {
    value: '45-54',
    label: '45 – 54 years old',
  },
  {
    value: '55-64',
    label: '55 – 64 years old',
  },
  {
    value: '65+',
    label: '65+ years old',
  },
  agePreferNotToSayOption,
];

export type CommonFormValues = {
  firstName: string;
  lastName: string;
  telephoneNumber: string | null;
  email: string | null;
  country: {
    value: string;
    label: string;
  };
  address1: string;
  address2: string | null;
  auPostcodeSuburb: AusPostcodeSuburbFieldOption | null;
  orgName: string | null;
  suburb: string | null;
  state: string | null;
  postcode: string | null;

  subscriptionOrderId: number | null;
  viewToken: string | null;
  orderStatus: OutstandingOderStatus | null;
};

export const CommonFormDefaultValues: CommonFormValues = {
  firstName: '',
  lastName: '',
  telephoneNumber: null,
  email: null,
  country: { value: 'AU', label: 'Australia' },
  address1: '',
  address2: null,
  auPostcodeSuburb: null,
  orgName: null,
  suburb: null,
  state: null,
  postcode: null,
  subscriptionOrderId: null,
  viewToken: null,
  orderStatus: null,
};

export type SubscribeFormValues = CommonFormValues & {
  productId: number | null;
  term: PaymentTerm;
  vouchers: { id: string }[];
  presetDonationAmount: string;
  customDonationAmount: string;
  favouriteShow: { value: string; label: string };
  contactId: number | null;
  reminderId: number | null;
  numberOfCards: number | null;
  age: { value: string; label: string };
  petName: string | null;
  giftPurchaserEmail: string | null;
  giftPurchaserName: string | null;
};

export const SubscribeFormDefaultValues: SubscribeFormValues = {
  ...CommonFormDefaultValues,
  productId: null,
  term: 'one_time',
  vouchers: [],
  presetDonationAmount: '0',
  customDonationAmount: '',
  favouriteShow: { value: '', label: 'All' },
  contactId: null,
  reminderId: null,
  numberOfCards: 1,
  age: agePreferNotToSayOption,
  petName: null,
  giftPurchaserEmail: null,
  giftPurchaserName: null,
};

export type AdminPurchaseSouce = 'POS' | 'Phoneroom - Stripe' | 'Postal' | 'Outgoing Call';

export type PaymentMethod =
  | 'Credit Card'
  | 'Cash'
  | 'EFTPOS'
  | 'Pledge Only'
  | 'Cheque'
  | 'Direct Deposit';

export type ValidPaymentMethods = Record<AdminPurchaseSouce, PaymentMethod[]>;

export const VALID_PAYMENT_METHODS: ValidPaymentMethods = {
  POS: ['Cash', 'EFTPOS', 'Pledge Only', 'Cheque', 'Direct Deposit'],
  'Phoneroom - Stripe': ['Credit Card', 'Pledge Only'],
  Postal: ['Credit Card', 'Cheque'],
  'Outgoing Call': ['Credit Card', 'Pledge Only'],
};

export type PhoneroomFormValues = CommonFormValues & {
  productId: string | null;
  vouchers: { id: string }[];
  presenterNote: string | null;
  staffNote: string | null;
  paymentMethod: PaymentMethod;
  contactId?: number;
  customDonationAmount: string;
  source: AdminPurchaseSouce;
  favouriteShow?: string;
  cardholderName: string | null;
  cardError: string | undefined;
  numberOfCards: number | null;
  age: { value: string; label: string };
  petName: string | null;
};

export const PhoneroomFormDefaultValues: PhoneroomFormValues = {
  ...CommonFormDefaultValues,
  productId: null,
  presenterNote: null,
  staffNote: null,
  vouchers: [],
  paymentMethod: 'Credit Card',
  customDonationAmount: '0',
  source: 'Phoneroom - Stripe',
  favouriteShow: undefined,
  cardholderName: null,
  cardError: undefined,
  numberOfCards: 1,
  age: agePreferNotToSayOption,
  petName: null,
};

export type PresentedShow = {
  name: string;
};

export type MembershipFormValues = {
  firstName: string;
  lastName: string;
  telephoneNumber: string | null;
  email: string | null;
  country: {
    value: string;
    label: string;
  };
  address1: string;
  address2: string | null;
  auPostcodeSuburb: AusPostcodeSuburbFieldOption | null;
  suburb: string | null;
  state: string | null;
  postcode: string | null;

  showsPresented: PresentedShow[];

  gender: 'male' | 'female' | 'gvnb' | 'other' | 'not_specified';
  indigenous: 'yes' | 'no' | 'not_specified';
  country_of_birth: {
    value: string;
    label: string;
  };
  country_of_birth_prefer_not_to_say: boolean | undefined;
  lote_at_home: 'yes' | 'no' | 'not_specified';
  age: 'under_18' | '18-24' | '25-34' | '35-44' | '45-54' | '55-64' | '65+' | 'not_specified';
  payment_method: {
    value: 'Online - Card' | 'Cheque' | 'Direct Deposit';
    label: 'Credit Card' | 'Cheque' | 'Direct Deposit';
  };
};

export const MembershipFormDefaultValues: Partial<MembershipFormValues> = {
  firstName: '',
  lastName: '',
  telephoneNumber: null,
  email: null,
  country: { value: 'AU', label: 'Australia' },
  address1: '',
  address2: null,
  auPostcodeSuburb: null,
  suburb: null,
  state: null,
  postcode: null,

  showsPresented: [],
  gender: undefined,
  country_of_birth: { value: 'AU', label: 'Australia' },
  country_of_birth_prefer_not_to_say: false,
  payment_method: {
    value: 'Online - Card',
    label: 'Credit Card',
  },
};
